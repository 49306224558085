import React from "react";
import { Modal } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';

function ToastPopup(props) {
  const { success, toggleSuccess, messages } = props;
  // console.log("Tour Time not available", messages)
  return (
    <div>

      <Modal show={success} onHide={toggleSuccess} dialogClassName="my-modal" className="ModalDiv">
        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div className={style1.successtext}>
            <p style={{ textAlign: "center", fontFamily: "DejaVuSansBold" }}>{messages}</p>

          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ToastPopup